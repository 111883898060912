import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { SvgFilter, SvgCloseCrossSmall } from '@getgrover/ui';
import { useTranslation } from 'react-i18next';

import { tk } from '@/i18n/translationKeys';
import {
  trackYourPaymentsPageFilterChipClickEvent,
  trackYourPaymentsPageFilterTypeClickEvent,
  trackYourPaymentsPageFilterOptionClickEvent,
} from '@/analytics';
import { useQueryParams } from '@/hooks/useQueryParams';
import { useApplicationData } from '@/providers/applicationData';
import { FilterTag } from '../FilterTag';
import { useFilterOptions } from '../useFilterOptions';
import { FilterTagOption, FilterType } from '../types';

import { FilterPaneRoot } from './FilterPane.styles';

interface FilterPaneProps {
  filterValueCount?: number;
  selectedFilters: FilterType[];
  setSelectedFilters: React.Dispatch<React.SetStateAction<FilterType[]>>;
}

export const FilterPane = ({
  filterValueCount,
  selectedFilters,
  setSelectedFilters,
}: FilterPaneProps) => {
  const queryParams = useQueryParams();
  const { device: deviceValue, status: statusValue, paymentType: PaymentTypeValue } = queryParams;

  const [filterValue, setFilterValue] = useState<string>('');
  const [isDeviceOpen, setIsDeviceOpen] = useState<boolean>(false);
  const [isStatusOpen, setIsStatusOpen] = useState<boolean>(false);
  const [isPaymentTypeOpen, setIsPaymentTypeOpen] = useState<boolean>(false);

  const { t } = useTranslation();
  const { userId, storeCode, routeBuilder } = useApplicationData();
  const navigate = useNavigate();
  const { filterOptions, deviceOptions, deviceOptionsLoading, statusOptions, paymentTypeOptions } =
    useFilterOptions({
      searchTerm: '',
      filter: {},
      first: 1000,
    });

  const getTagOpen = (type: FilterType) => {
    switch (type) {
      case FilterType.Device:
        setIsDeviceOpen(!isDeviceOpen);
        break;
      case FilterType.Status:
        setIsStatusOpen(!isStatusOpen);
        break;
      case FilterType.PaymentType:
        setIsPaymentTypeOpen(!isPaymentTypeOpen);
        break;
      default:
        break;
    }
  };

  const handleFilterTypeOpenChange = (type: FilterType) => {
    setFilterValue('');
    getTagOpen(type);
  };

  const handleFilterChipOpenChange = (open: boolean) => {
    if (open) {
      trackYourPaymentsPageFilterChipClickEvent({
        userId,
        store: storeCode,
      });
    }
  };

  const handleFilterChange = (type: string) => {
    getTagOpen(type as FilterType);

    setSelectedFilters((prevSelectedFilters) =>
      prevSelectedFilters.includes(type as FilterType)
        ? prevSelectedFilters
        : [...prevSelectedFilters, type as FilterType]
    );

    setFilterValue(type);

    trackYourPaymentsPageFilterTypeClickEvent({
      userId,
      store: storeCode,
      filterType: type,
    });
  };

  const handleValueChange = (value: string, type: FilterType) => {
    navigate(routeBuilder.buildFilterPaymentsRoute({ query: { ...queryParams, [type]: value } }));

    trackYourPaymentsPageFilterOptionClickEvent({
      userId,
      store: storeCode,
      filterType: type,
      filterOption: value,
    });
  };

  const renderFilterValue = () => {
    return filterValueCount
      ? `${t(tk.yourPaymentsFilterChipTitle)} (${filterValueCount})`
      : `${t(tk.yourPaymentsFilterChipTitle)}`;
  };

  const renderSelectedValue = (typePrefix: string, options: FilterTagOption[], value: string) => {
    const selectedOption = options?.find((option) => option.value === value);

    if (!selectedOption) {
      return null;
    }

    return (
      <span>
        {typePrefix} <b>{selectedOption.label}</b>
      </span>
    );
  };

  const handleFilterTagCloseClick = (event: React.MouseEvent<HTMLElement>, type: FilterType) => {
    event.stopPropagation();
    const tempSelectedFilters = selectedFilters.filter((filter) => filter !== type);
    setSelectedFilters(tempSelectedFilters);
    setFilterValue('');

    const newQueryParams = { ...queryParams };
    delete newQueryParams[type];
    navigate(routeBuilder.buildFilterPaymentsRoute({ query: newQueryParams }));
  };

  const filterConfigs: Record<
    FilterType,
    {
      placeholder: string;
      open: boolean;
      value: string;
      options: FilterTagOption[];
      optionsLoading?: boolean;
      onOpenChange: () => void;
      onValueChange: (value: string) => void;
    }
  > = {
    [FilterType.Device]: {
      placeholder: `${t(tk.yourPaymentsFilterTitleDevice)}:`,
      open: isDeviceOpen,
      value: deviceValue,
      options: deviceOptions,
      optionsLoading: deviceOptionsLoading,
      onOpenChange: () => handleFilterTypeOpenChange(FilterType.Device),
      onValueChange: (value: string) => handleValueChange(value, FilterType.Device),
    },
    [FilterType.Status]: {
      placeholder: `${t(tk.yourPaymentsListTitleStatus)}:`,
      open: isStatusOpen,
      value: statusValue,
      options: statusOptions,
      onOpenChange: () => handleFilterTypeOpenChange(FilterType.Status),
      onValueChange: (value: string) => handleValueChange(value, FilterType.Status),
    },
    [FilterType.PaymentType]: {
      placeholder: `${t(tk.yourPaymentsFilterTitlePaymentType)}:`,
      open: isPaymentTypeOpen,
      value: PaymentTypeValue,
      options: paymentTypeOptions,
      onOpenChange: () => handleFilterTypeOpenChange(FilterType.PaymentType),
      onValueChange: (value: string) => handleValueChange(value, FilterType.PaymentType),
    },
  };

  const renderSelectedFilterTag = () => {
    return selectedFilters.map((filterType) => {
      const config = filterConfigs[filterType];
      if (!config) return null;

      const { optionsLoading, placeholder, open, onOpenChange, onValueChange, value, options } =
        config;

      if (filterType === FilterType.Device && optionsLoading) {
        return null;
      }

      return (
        <FilterTag
          key={filterType}
          wrapperIcon={<SvgCloseCrossSmall />}
          placeholder={placeholder}
          open={open}
          onOpenChange={onOpenChange}
          onValueChange={onValueChange}
          value={value}
          options={options}
          selectedValueDisplay={renderSelectedValue(placeholder, options, value)}
          iconClickable
          onIconClick={(e: React.MouseEvent<HTMLElement>) =>
            handleFilterTagCloseClick(e, filterType)
          }
          optionsScrollable
          triggerPadding="0 0 0 8px"
          triggerBackgroundColor="transparent"
          contentWrapperMaxWidth={filterType === FilterType.Device ? '412px' : undefined}
        />
      );
    });
  };

  return (
    <FilterPaneRoot>
      <FilterTag
        wrapperIcon={<SvgFilter />}
        placeholder={renderFilterValue()}
        onOpenChange={handleFilterChipOpenChange}
        onValueChange={handleFilterChange}
        value={filterValue}
        options={filterOptions}
        selectedValueDisplay={<span>{renderFilterValue()}</span>}
        contentWrapperMaxWidth="168px"
      />
      {renderSelectedFilterTag()}
    </FilterPaneRoot>
  );
};
