import React, { useEffect, useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';
import { SvgFilter } from '@getgrover/ui';

import { tk } from '@/i18n/translationKeys';
import {
  trackYourPaymentsPageVisitedEvent,
  trackYourPaymentsPageFilterChipClickEvent,
} from '@/analytics';
import { useScreenMatch } from '@/hooks/useScreenMatch';
import { useQueryParams } from '@/hooks/useQueryParams';
import { useApplicationData } from '@/providers/applicationData';
import { getLocale } from '@/utils/cookies';
import { LoadingUnit } from '@/components/Loading';
import { DisplayIf } from '@/components/DisplayIf';
import { Page } from '@/components/Page';
import { DocumentTitle, PageName } from '@/components/DocumentTitle';
import { Text } from '@/components/Text';
import { Wrapper } from '@/components/Wrapper';
import { ArrowLeftIcon } from '@/icons/ArrowLeftIcon';
import { PaymentState, PaymentEnum } from '@/generated/types';
import { DEFAULT_LOCALE } from './constants';
import { TopPane } from './TopPane';
import { LinkToPaymentMethods } from './LinkToPaymentMethods';
import { FilterPane } from './FilterPane';
import { PaymentsList } from './PaymentsList';
import { usePaymentsList } from './PaymentsList/usePaymentsList';
import { FiltersScreen } from './FiltersScreen';
import { FilterType } from './types';

import { Root, BackButton, FilterButton } from './YourPaymentsPage.styles';

export const YourPaymentsPage = (): JSX.Element => {
  const queryParams = useQueryParams();
  const { subscriptionId, device, status, paymentType } = queryParams;
  const selectedTypes: FilterType[] = Object.entries(queryParams)
    .filter(([key, value]) => Boolean(value) && key !== 'subscriptionId')
    .map(([key]) => key as FilterType);
  const [selectedFilters, setSelectedFilters] = useState<FilterType[]>(selectedTypes);
  const [showFiltersScreen, setShowFiltersScreen] = useState<boolean>(false);

  const { t } = useTranslation();
  const { isMobile } = useScreenMatch();
  const { userId, activeStore, storeCode, flags } = useApplicationData();
  const navigate = useNavigate();
  const locale = getLocale() || DEFAULT_LOCALE;
  const theme = useTheme();

  const queryParamsFilterValues = [device, status, paymentType];

  const isFilterEmptyResult =
    (status === PaymentState.FAILED && paymentType === PaymentEnum.ORDER_PAYMENT) ||
    (status === 'CANCELED' && paymentType && paymentType !== PaymentEnum.ORDER_PAYMENT) ||
    (status === 'CANCELED' && device);

  const paymentsListResult = usePaymentsList({
    subscriptionId,
    device,
    status,
    paymentType,
    isFilterEmptyResult: !!isFilterEmptyResult,
  });

  const filterValueCount = queryParamsFilterValues.filter((value) => Boolean(value)).length;

  useEffect(() => {
    trackYourPaymentsPageVisitedEvent({
      path: location.pathname,
      store: storeCode,
      store_id: activeStore.store_id,
      locale,
    });
  }, []);

  const handleBackClick = useCallback(() => {
    navigate(-1); // Go back one step in history
  }, []);

  const handleFilterClick = () => {
    setShowFiltersScreen(true);
    trackYourPaymentsPageFilterChipClickEvent({
      userId,
      store: storeCode,
    });
  };

  const renderFilterButton = () => {
    if (subscriptionId) {
      return null;
    }
    if (paymentsListResult.loading && !filterValueCount) {
      return <LoadingUnit width={21} height={8} />;
    }
    return (
      <FilterButton size="S" variant="Primary" onClick={handleFilterClick}>
        <SvgFilter />
        {filterValueCount
          ? `${t(tk.yourPaymentsFilterChipTitle)} (${filterValueCount})`
          : `${t(tk.yourPaymentsFilterChipTitle)}`}
      </FilterButton>
    );
  };

  return showFiltersScreen && isMobile && flags.pmmp_filter_your_payments ? (
    <FiltersScreen
      totalCount={filterValueCount ? paymentsListResult?.data?.totalCount : null}
      paymentsListLoading={paymentsListResult?.loading}
      setShowFiltersScreen={setShowFiltersScreen}
    />
  ) : (
    <Page>
      <DocumentTitle pageName={PageName.yourPayments} />
      <Root isMobile={isMobile}>
        <DisplayIf desktop>
          <Wrapper height="64px" justifyContent="space-between" margin="16px 0">
            <Wrapper>
              <Text
                typography="Subheadline"
                color={theme.colors.text.darkerGrey}
                data-testid="your-payments-page-main-title"
              >
                {t(tk.yourPaymentsPageMainTitle)}
              </Text>
            </Wrapper>

            <LinkToPaymentMethods />
          </Wrapper>
        </DisplayIf>

        <DisplayIf mobile>
          <Wrapper height="64px" justifyContent="space-between" margin="0 0 8px">
            <Wrapper>
              <BackButton onClick={handleBackClick}>
                <ArrowLeftIcon />
              </BackButton>

              <Text
                typography="SuperTinyHeadline"
                color={theme.colors.text.darkerGrey}
                data-testid="your-payments-page-main-title"
              >
                {t(tk.yourPaymentsPageMainTitle)}
              </Text>
            </Wrapper>

            <LinkToPaymentMethods />
          </Wrapper>
        </DisplayIf>

        <TopPane />

        {flags.pmmp_filter_your_payments ? (
          <>
            <DisplayIf desktop>
              <FilterPane
                filterValueCount={filterValueCount}
                selectedFilters={selectedFilters}
                setSelectedFilters={setSelectedFilters}
              />
            </DisplayIf>
            <DisplayIf mobile>{renderFilterButton()}</DisplayIf>
          </>
        ) : null}

        <PaymentsList
          paymentsListResult={paymentsListResult}
          filterValueCount={filterValueCount}
          setSelectedFilters={setSelectedFilters}
        />
      </Root>
    </Page>
  );
};
