import styled from 'styled-components';
import { Card, SonntagButtonV2 } from '@getgrover/ui';

export const PaymentCardRoot = styled.div<{
  isMobile: boolean;
  isModal?: boolean;
  loading?: boolean;
}>`
  display: flex;
  align-items: center;
  :hover {
    background-color: ${({ isModal, loading, theme }) =>
      isModal || loading ? 'unset' : theme.colors.background.superLightGrey};
    cursor: ${({ isModal, loading }) => (isModal || loading ? 'unset' : 'pointer')};
  }

  width: ${({ isModal }) => (isModal ? '100%' : 'unset')};
  margin: ${({ isMobile, isModal }) => (isMobile && !isModal ? '16px auto' : 'unset')};
  padding: ${({ isMobile, isModal }) => (isMobile || isModal ? 'unset' : '12px')};
  border-top: ${({ isMobile, isModal, theme }) =>
    isMobile || isModal ? 'unset' : `1px solid ${theme.colors.stroke.lighterGrey}`};
`;

export const IconCircle = styled.div`
  position: relative;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  background: ${({ theme }) => theme.colors.hover.lighterGrey};
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 8px;
`;

export const Content = styled.div`
  margin-right: 212px;
  flex-grow: 1;
  overflow: hidden;
  width: 360px;
`;

export const MaxTwoLinesTitle = styled.div`
  max-height: 54px;
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: break-word;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`;

export const Strikethrough = styled.span<{ isFailedOrderPayment?: boolean }>`
  text-decoration: line-through;
  margin-right: ${({ isFailedOrderPayment }) => (isFailedOrderPayment ? '0' : '4px')};
  color: ${({ theme }) => theme.colors.text.baseGrey};
`;

export const PaymentMobileCard = styled(Card)`
  width: 100%;
`;

export const DownloadInvoiceButton = styled(SonntagButtonV2)`
  border-color: transparent;
`;

export const TooltipContainer = styled.div`
  position: relative;
  display: inline-block;
`;

export const TooltipText = styled.span`
  visibility: hidden;
  width: 320px;
  background-color: ${({ theme }) => theme.colors.background.darkerGrey};
  color: ${({ theme }) => theme.colors.text.white};
  text-align: left;
  border-radius: 6px;
  padding: 8px 12px;
  position: absolute;
  z-index: 1;
  bottom: 110%;
  left: 50%;
  margin-left: -160px;

  &::after {
    content: '';
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: ${({ theme }) =>
      `${theme.colors.background.darkerGrey} transparent transparent transparent`};
  }

  ${TooltipContainer}:hover &,
  &:hover {
    visibility: visible;
    cursor: default;
  }
`;

export const ExternalLink = styled.a`
  text-decoration: underline;
  cursor: pointer;
  white-space: nowrap;
`;

export const MoreInfoContainer = styled.div<{ justifyContent?: string }>`
  display: flex;
  justify-content: ${({ justifyContent }) => justifyContent};
  border-top: ${({ theme }) => `2px solid ${theme.colors.hover.lighterGrey}`};
  margin-top: 16px;
  padding-top: 16px;
`;
