import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';
import { DefaultButton, RadioGroup } from '@getgrover/ui';

import { tk } from '@/i18n/translationKeys';
import { trackYourPaymentsPageFilterOptionClickEvent, trackYourPaymentsPageFilterApplyClickEvent, trackYourPaymentsPageFilterClearAllClickEvent } from '@/analytics';
import { useApplicationData } from '@/providers/applicationData';
import { useQueryParams } from '@/hooks/useQueryParams';
import { Text } from '@/components/Text';
import { LoadingUnit } from '@/components/Loading';
import { ArrowLeftIcon } from '@/icons/ArrowLeftIcon';
import { UserType } from '@/generated/types';
import { useFilterOptions } from '../useFilterOptions';
import { FilterType } from '../types';

import { BackButton } from '../YourPaymentsPage.styles';
import { Root, HeaderWrapper, ContentGroup, ButtonWrapper } from './FiltersScreen.styles';

interface FiltersScreenProps {
  totalCount?: number | null;
  paymentsListLoading?: boolean;
  setShowFiltersScreen: React.Dispatch<React.SetStateAction<boolean>>;
}

export const FiltersScreen = ({
  totalCount,
  paymentsListLoading,
  setShowFiltersScreen,
}: FiltersScreenProps) => {
  const { t } = useTranslation();
  const queryParams = useQueryParams();
  const navigate = useNavigate();
  const theme = useTheme();
  const { userId, storeCode, user, routeBuilder } = useApplicationData();

  const { deviceOptions, deviceOptionsLoading, statusOptions, paymentTypeOptions } =
    useFilterOptions({
      searchTerm: '',
      filter: {},
      first: 1000,
    });

  const handleApplyClick = (name: string) => {
    setShowFiltersScreen(false);

    trackYourPaymentsPageFilterApplyClickEvent({
      userId,
      store: storeCode,
      applyButtonName: name,
    });
  };

  const getValue = (type: FilterType) => {
    if (type in queryParams) {
      return queryParams[type];
    }
  };

  const handleDeviceChange = (value: string) => {
    navigate(
      routeBuilder.buildFilterPaymentsRoute({
        query: { ...queryParams, [FilterType.Device]: value },
      })
    );

    trackYourPaymentsPageFilterOptionClickEvent({
      userId,
      store: storeCode,
      filterType: FilterType.Device,
      filterOption: value,
    });
  };

  const handleStatusChange = (value: string) => {
    navigate(
      routeBuilder.buildFilterPaymentsRoute({
        query: { ...queryParams, [FilterType.Status]: value },
      })
    );

    trackYourPaymentsPageFilterOptionClickEvent({
      userId,
      store: storeCode,
      filterType: FilterType.Status,
      filterOption: value,
    });
  };

  const handlePaymentTypeChange = (value: string) => {
    navigate(
      routeBuilder.buildFilterPaymentsRoute({
        query: { ...queryParams, [FilterType.PaymentType]: value },
      })
    );

    trackYourPaymentsPageFilterOptionClickEvent({
      userId,
      store: storeCode,
      filterType: FilterType.PaymentType,
      filterOption: value,
    });
  };

  const handleClearAllClick = () => {
    navigate(routeBuilder.buildFilterPaymentsRoute({ query: {} }));

    trackYourPaymentsPageFilterClearAllClickEvent({
      userId,
      store: storeCode,
    });
  };

  return (
    <Root>
      <HeaderWrapper>
        <BackButton onClick={() => handleApplyClick('Back')}>
          <ArrowLeftIcon />
        </BackButton>

        <Text
          typography="SmallHeadline"
          color={theme.colors.text.darkerGrey}
          data-testid="your-payments-filter-main-title"
        >
          {t(tk.yourPaymentsFilterMainTitle)}
        </Text>
      </HeaderWrapper>

      <ContentGroup vertical mobileGap={6}>
        {user?.type !== UserType.BUSINESS ? (
          <>
            <Text
              block
              uppercase
              color={theme.colors.text.darkerGrey}
              typography="SmallCapsHeadline"
            >
              {t(tk.yourPaymentsFilterTitleDevice)}
            </Text>
            {deviceOptionsLoading ? (
              [1, 2, 3, 4].map((key) => <LoadingUnit key={key} height={6} />)
            ) : (
              <RadioGroup
                value={getValue(FilterType.Device)}
                onChange={handleDeviceChange}
                options={deviceOptions}
                labelPlacement="start"
                orientation="vertical"
              />
            )}
          </>
        ) : null}

        <Text block uppercase color={theme.colors.text.darkerGrey} typography="SmallCapsHeadline">
          {t(tk.yourPaymentsListTitleStatus)}
        </Text>
        <RadioGroup
          value={getValue(FilterType.Status)}
          onChange={handleStatusChange}
          options={statusOptions}
          labelPlacement="start"
          orientation="vertical"
        />

        <Text block uppercase color={theme.colors.text.darkerGrey} typography="SmallCapsHeadline">
          {t(tk.yourPaymentsFilterTitlePaymentType)}
        </Text>
        <RadioGroup
          value={getValue(FilterType.PaymentType)}
          onChange={handlePaymentTypeChange}
          options={paymentTypeOptions}
          labelPlacement="start"
          orientation="vertical"
        />
      </ContentGroup>

      <ButtonWrapper>
        <DefaultButton
          size="M"
          variant="Ghost"
          disabled={!Object.values(queryParams).length}
          onClick={handleClearAllClick}
        >
          {t(tk.yourPaymentsClearAllButtonText)}
        </DefaultButton>
        <DefaultButton
          disabled={paymentsListLoading}
          size="M"
          variant="Primary"
          onClick={() => handleApplyClick('Show')}
        >
          {t(tk.yourPaymentsShowAllButtonText)}
          {Object.values(queryParams).length ? ` (${totalCount})` : null}
        </DefaultButton>
      </ButtonWrapper>
    </Root>
  );
};
