import { sendAnalyticsEvent } from '@devsbb/analytics-client';

type EventProperties = {
  userId: string;
  store: string;
  subscriptionId?: string;
  filterType?: string;
  filterOption?: string;
  applyButtonName?: string;
};

export const trackYourPaymentsPagePaymentItemClickEvent = (properties: EventProperties) => {
  sendAnalyticsEvent<EventProperties>('Payments Item Clicked', properties);
};

export const trackYourPaymentsPageInvoiceDownloadClickEvent = (properties: EventProperties) => {
  sendAnalyticsEvent<EventProperties>('Download Invoice Button Clicked', properties);
};

export const trackYourPaymentsPageSubscriptionLinkClickEvent = (properties: EventProperties) => {
  sendAnalyticsEvent<EventProperties>('Subscription Link Clicked', properties);
};

export const trackYourPaymentsPagePaymentMethodsLinkClickEvent = (properties: EventProperties) => {
  sendAnalyticsEvent<EventProperties>('Payment Methods Link Clicked', properties);
};

export const trackYourPaymentsPageFilterChipClickEvent = (properties: EventProperties) => {
  sendAnalyticsEvent<EventProperties>('Filter Chip Clicked', properties);
};

export const trackYourPaymentsPageFilterTypeClickEvent = (properties: EventProperties) => {
  sendAnalyticsEvent<EventProperties>('Filter Type Clicked', properties);
};

export const trackYourPaymentsPageFilterOptionClickEvent = (properties: EventProperties) => {
  sendAnalyticsEvent<EventProperties>('Filter Option Clicked', properties);
};

export const trackYourPaymentsPageFilterApplyClickEvent = (properties: EventProperties) => {
  sendAnalyticsEvent<EventProperties>('Filter Apply Button Clicked', properties);
};

export const trackYourPaymentsPageFilterClearAllClickEvent = (properties: EventProperties) => {
  sendAnalyticsEvent<EventProperties>('Filter Clear All Button Clicked', properties);
};
